import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import Print from "./components/Print";

import "./App.css";
import "./Print.css";
import Icon from "./components/Icon";

function App() {
  const printRef = useRef<HTMLDivElement>(null);

  const [data, setData] = useState({
    title: "Nama Parfum 1, Nama Parfum 2",
    size: "35",
  });
  return (
    <>
      <div
        style={{
          width: "90%",
          maxWidth: "750px",
          margin: "auto",
          position: "relative",
        }}
      >
        <h1>Label ABZ Parfum</h1>
        <div>
          <label className="input-label">Nama Parfum</label>
          <textarea
            value={data.title}
            onChange={(e) => setData({ ...data, title: e.target.value })}
          />
          <small>
            Kamu dapat menncetak hingga 14 jenis ukuran. Pisahkan setiap ukuran
            dengan tanda koma (,).
          </small>
        </div>
        <div>
          <label className="input-label">Ukuran (Ml)</label>
          <textarea
            value={data.size}
            onChange={(e) => setData({ ...data, size: e.target.value })}
          />
          <small>
            Kamu dapat menncetak hingga 14 jenis ukuran. Pisahkan setiap ukuran
            dengan tanda koma (,).
          </small>
        </div>

        {/* Preview */}
        <div>
          <span className="input-label">Pratinjau:</span>
          <div style={{ margin: "auto" }}>
            <div className="label">
              <div className="label-icon">
                <Icon />
              </div>
              <div className="label-title">
                {data.title.split(",")[0]?.trim()}
              </div>

              <div className="label-detail">
                <div>Eau De Parfume</div>
                <div
                  style={{ borderBottom: "dashed 1px", margin: "2px" }}
                ></div>
                <div>
                  <strong>{data.size.split(",")[0]?.trim()} ML</strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReactToPrint
          documentTitle="Label"
          trigger={() => <button>Print</button>}
          content={() => printRef.current}
        />
        <div style={{ display: "none" }}>
          {/* <div className=""> */}
          <Print title={data.title} size={data.size} ref={printRef} />
        </div>

        <footer
          style={{
            textAlign: "center",
            padding: 20,
            marginTop: 100,
            borderTop: "solid 1px",
          }}
        >
          <a href="https://xvii.dev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              xmlSpace="preserve"
              height={60}
              width={60}
            >
              <path d="M19.3 11.6h-1.5V.8h1.5v10.8zM10 14h4v-1.5h-4V14zM16.7.8h-1.5v10.7h1.5V.8zm-5.2 15.8h1.8v-1.5H10v8.1h4v-1.5h-2.6v-5.1zM7.3 3.7l-.5.5-.5-.5V.8H4.8v3.5l1 1-1 1v5.3h1.5V6.9l.5-.5.6.6v4.6h1.5V6.4l-1-1 1-1V.8H7.3v2.9zm5.2 5.2l-.5.5-.5-.5V.8H10v8.7l2 2 2-2V.8h-1.5v8.1zm5.3 3.5v8.1l-.6.6-.5-.5v-8.1h-1.5v8.7l2 2 2.1-2.1v-8.7h-1.5zm-9 0v8.2l-2.6 2.6H4.7V12.5c0-.1 4.1-.1 4.1-.1zM7.3 14H6.2v7.1L7.3 20v-6z"></path>
            </svg>
          </a>
        </footer>
      </div>
    </>
  );
}

export default App;
