import React from "react";
import Icon from "./Icon";

const Print = React.forwardRef<HTMLDivElement, PrintProps>(
  ({ title: stringTitle, size: stringSize }, ref) => {


    const judul = stringTitle.split(",");
    const size = stringSize.split(",");

    return (
      <div className="paper" ref={ref}>
        <div className="layout">
          {Array.from({ length: 14 }).map((_e, index) => {
            return (
              <div
                key={index}
                className={
                  index === 3 || index === 10 ? "label rotate" : "label"
                }
              >
                <div className="label-icon">
                  <Icon />
                </div>
                <div className="label-title">
                  {judul[index % judul.length]?.trim()}
                </div>

                <div className="label-detail">
                  <div>Eau De Parfume</div>
                  <div
                    style={{ borderBottom: "dashed 1px", margin: "2px" }}
                  ></div>
                  <div>
                    <strong>{size[index % size.length]?.trim()} ML</strong>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default Print;

interface PrintProps {
  title: string;
  size: string;
}
